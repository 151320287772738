<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full text-justify lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Assistant Professor Social Sciences & Liberal Arts. She
                      holds a PhD in Religion, Philosophy and Ethics from
                      University of Queensland. Dr. Raquib's project "Culturally
                      Informed Pro-Social AI Regulation and Persuasion
                      Framework" has won a grant under the Facebook Research
                      Ethics in AI Research Initiative for the Asia Pacific. In
                      this work, a culturally-informed pro-social AI regulation
                      and persuasion framework for Pakistan and the Muslim world
                      will be proposed. In this regard, the rich Islamic legal
                      tradition and the work on objectives (Maqasid) of Islamic
                      law will be leveraged for studying contemporary AI-related
                      issues. Through the proposed framework, the project team
                      will seek to engage with other AI ethics frameworks and
                      propose new insights that can advance the general body of
                      knowledge on AI/ML ethical principles and guidelines.
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      <a class="italic font-bold"
                        >Some of her recent works are:</a
                      ><br />
                      - Islamic Ethics of Technology: An Objectives (Maqasid)
                      Approach. March 2015. The Other Press Malaysia (Formerly
                      Islamic Book Trust).<br />
                      - Chapter 11, Maqāṣid al-Shari‘ah: A Traditional Source
                      for Ensuring Design and Development of Modern Technology
                      for Humanity’s Benefit in Islamic Perspectives on Science
                      and Technology:<br />
                      - Selected Conference Papers, 2016. Kamali, M.H., Bakar,
                      O., Batchelor, D.A.,F., Hashim, R. (Eds). Springer.
                      Singapore.
                      <br />- On Combating Fake News, Misinformation, and
                      Machine Learning Generated Fakes: Insights from the
                      Islamic Ethical Tradition, Talat Zubair, Amana Raquib,
                      Junaid Qadir . ICR (Islamic Civilizational Renewal) Dec.
                      2019 Vol 10 (2)<br />- Entrepreneurship as an Agent for
                      Social-Ethical Reform: An Islamic Perspective. Amana
                      Raquib Imran Khan ICR (Islamic Civilizational Renewal)June
                      2019 Vol 10 (1)<br />- Islamic Perspective on Social Media
                      Technology, Addiction, and Human Values Talat Zubair,
                      Amana Raquib.
                      https://journals.umt.edu.pk/index.php/JITC/article/view/622<br />-
                      Creation of the Islamic Self for Sustainability: Can
                      Muslim Entrepreneu -rship Positively Contribute to the
                      SDGs through Tazk¯ıyah and Tarb¯ıyah of the Muslim Youth
                      .Amana Raquib, Omar Javaid and Gulnaz Anjum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/amanaRaquib.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Dr. Amana Raquib",
      location: "Pakistan",
      affiliation: "Institute of Business Administration, Karachi",
    };
  },
  components: {},
};
</script>

import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";
import Bio from "@/layouts/Bio.vue"

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import Maps from "@/views/admin/Maps.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";

// custom pages
import Schedule from "@/views/Schedule.vue"
import Speakers from "@/views/Speakers.vue"
import Video from "@/views/Video.vue"

// Profiles
import AasimPadela from "@/views/speakers/AasimPadela.vue"
import KhaliqAzad from "@/views/speakers/KhaliqAzad.vue"
import WahabSuri from "@/views/speakers/WahabSuri.vue"
import Amana from "@/views/speakers/Amana.vue"
import AmirHussain from "@/views/speakers/AmirHussain.vue"
import AtharMansoor from "@/views/speakers/AtharMansoor.vue"
import Ildus from "@/views/speakers/IldusRafikov.vue"
import JaserAuda from "@/views/speakers/JaserAuda.vue"
import JunaidQadir from "@/views/speakers/JunaidQadir.vue"
import Ghaly from "@/views/speakers/Ghaly.vue"
import MuftiAkhlaq from "@/views/speakers/MuftiAkhlaq.vue"
import OmarJavaid from "@/views/speakers/OmarJavaid.vue"
import ShahzebKhan from "@/views/speakers/ShahzebKhan.vue"
import ZeeshanAhmed from "@/views/speakers/ZeeshanAhmed.vue"
import HamzaKaramli from "@/views/speakers/HamzaKaramali.vue"
import ImranHussain from "@/views/speakers/ImranHussain.vue"

// routes

const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/tables",
        component: Tables,
      },
      {
        path: "/admin/maps",
        component: Maps,
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/schedule",
    component: Schedule,
  },
  {
    path: "/speakers",
    component: Speakers
  },
  {
    path: "/video",
    component: Video
  },
  {
    path: "/bio",
    component: Bio,
    children: [
      {
        path: "/bio/aasimpadela",
        component: AasimPadela,
      },
      {
        path: "/bio/khaliqazad",
        component: KhaliqAzad,
      },
      {
        path: "/bio/wahabsuri",
        component: WahabSuri,
      },
      {
        path: "/bio/amana",
        component: Amana
      },
      {
        path: "/bio/amirhussain",
        component: AmirHussain
      },
      {
        path: "/bio/atharmansoor",
        component: AtharMansoor
      },
      {
        path: "/bio/ildus",
        component: Ildus
      },
      {
        path: "/bio/jaserauda",
        component: JaserAuda
      },
      {
        path: '/bio/junaidqadir',
        component: JunaidQadir
      },
      {
        path: '/bio/ghaly',
        component: Ghaly
      },
      {
        path: '/bio/muftiakhlaq',
        component: MuftiAkhlaq
      },
      {
        path: '/bio/omarjavaid',
        component: OmarJavaid
      },
      {
        path: '/bio/shahzebkhan',
        component: ShahzebKhan
      },
      {
        path: '/bio/zeeshanahmed',
        component: ZeeshanAhmed
      },
      {
        path: '/bio/hamzakaramali',
        component: HamzaKaramli
      },
      {
        path: '/bio/imranhussain',
        component: ImranHussain
      }
    ],
  },
  {
    path: "/",
    component: Index,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).mount("#app");

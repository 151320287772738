<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Dr. Ildus Rafikov is a Senior Fellow and Manager of
                      Research, Science & Technology at Maqasid Institute
                      Global. He holds a PhD from ISTAC/IIUM in Philosophy,
                      Ethics and Contemporary issues with special interest in
                      Islamic economics, banking and finance, history and
                      education. He also holds a professional post-graduate
                      certificate in Islamic banking and finance from the
                      International Centre for Education in Islamic Finance
                      (INCEIF), Kuala Lumpur and a bachelor’s degree in
                      Communications from IIUM.
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Dr. Ildus Rafikov has written and published a number of
                      academic articles in indexed and non-indexed journals in
                      English and Russian languages. His publications in English
                      language include article entitled: “The Methodology of
                      Integrated Knowledge in Islamic Economics and Finance:
                      Collective Ijtihād”, “Scarcity in the age of abundance:
                      paradox and remedies”, “Factors Preventing the
                      Actualisation of Ummah Wasata in the Republic of
                      Tatarstan, Russia”, “An Analysis of Financial Speculation:
                      from the Maqasid Al-Shari’ah Perspective.” Publications in
                      Russian language include “Cooperative JAK Bank in Sweden:
                      Possibility of adapting its model in the Russian
                      Federation”, “Objectives of Shariah in Islamic Financial
                      Transactions, and the Problem of the Malaysian Islamic
                      Finance Model”, “Islamic Microfinancing in Russia”,
                      “Global Financial Crisis from the viewpoint of Islamic
                      Economics”, and “A Short Guide to Islamic Finance,
                      Investment and Insurance”. In addition, he has upcoming
                      publications on monetary policy, fourth industrial
                      revolution, moral values education and principles and
                      practices of waqf.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/ildusRafikov.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Dr. Ildus Rafikov",
      location: "Turkey",
      affiliation: "Maqasid Institute Global",
    };
  },
  components: {},
};
</script>

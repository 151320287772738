<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Mufti Muhammad Akhlaq is a recognized Shariah Scholar and
                      researcher having strong comprehension of all aspects of
                      Islamic Law and specialized in Islamic Jurisprudence and
                      Islamic Finance from Jamia Dar Ul Uloom Karachi, Pakistan,
                      having Takhassus Fil Ifta (Specialization in Islamic
                      Jurisprudence), majoring in Islamic Banking & Finance.
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      He is permanent faculty member and serving as Lecturer,
                      Motivational Speaker and Counselor at University of
                      Management and Technology (UMT) and Shariah Advisor of
                      Asia Insurance Window Takaful operations.(AL-WTO) He is
                      also serving as the Shariah Compliance Supervisor (Central
                      & North) and Shariah Trainer at Pak Qatar Family Takaful
                      Limited since 2012 to date and was looking after the
                      transactions, day to day Shariah matters. His specialties
                      include Shariah Compliant investments, development of
                      takaful products, policies, manuals and drafting of
                      Shariah related documents. Mufti Akhlaq is also involved
                      in delivering detailed trainings to the management of all
                      levels, marketing, distribution force of ‘Pak Qatar Family
                      Takaful’and Ulmai-e-kiram on Takaful, Shariah related
                      issues and requirements in the light of Shariah and
                      Takaful Rules 2005-2012.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/muftiAkhlaq.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Mufti Muhammad Akhlaq",
      location: "Pakistan",
      affiliation:
        "Asia Window Takaful Operations and University of Management and Technology",
    };
  },
  components: {},
};
</script>

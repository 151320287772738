<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Mr. Imran works with tech startups, corporate enterprises
                      and public-sector organizations with the aim of creating
                      well-designed products, services, systems and world-class
                      user experiences that lead to customer satisfaction,
                      differentiation in the market and sustainable growth. As a
                      design leader and user experience practitioner, he has
                      expertise in formulating digital transformation
                      strategies, developing product roadmaps, building and
                      managing multidisciplinary design and innovation teams,
                      coaching and mentoring, setting up design studios,
                      developing design systems and establishing governance
                      frameworks. He also focus on developing organizational
                      cultures that enable people to perform design and
                      innovation-based work.
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Mr. Imran has over 25 years of experience working with
                      clients across the globe in a variety of industry sectors.
                      His most recent work has involved redesigning a digital
                      consumer banking experience, conceptualizing an IoT-based
                      industrial automation platform, executive supervision of a
                      fintech startup’s product design, formulating the digital
                      strategy for an educational institute, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/imranHussain.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Imran Hussain",
      location: "Pakistan",
      affiliation: "Design Consultant at Cordoba",
    };
  },
  components: {},
};
</script>
<template>
  <footer class="relative bg-blueGray-200 pt-8 pb-6">
    <div
      class="
        bottom-auto
        top-0
        left-0
        right-0
        w-full
        absolute
        pointer-events-none
        overflow-hidden
        -mt-20
        h-20
      "
      style="transform: translateZ(0)"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-center text-center lg:text-left">
        <div class="align-center md:w-2/12 px-4">
          <h4 class="text-1xl font-semibold text-center">Organized By</h4>
          <img
            class="mx-auto"
            src="https://itu.edu.pk/wp-content/themes/itu/images/itu_logo.png"
            height="100"
          />
        </div>
        <div class="align-center md:w-8/12 px-4">
          <h4 class="text-1xl font-semibold text-center">
            In Colloration With
          </h4>
          <div class="flex py-0 my-0 row-wrap justify-center">
            <img
              class="mx-4 object-scale-down"
              :src="AlQadirLogo"
              width="100"
            />
            <img
              class="mx-4 object-scale-down"
              :src="NaseehaLogo"
              width="100"
            />
          </div>
        </div>
        <div class="items-center justify-center md:w-2/12 px-4">
          <h4 class="text-1xl font-semibold text-center">Funded By</h4>
          <img
            class="mx-auto pt-2"
            src="https://www.facebook.com/images/fb_icon_325x325.png"
            width="95"
          />
        </div>
      </div>

      <hr class="my-6 border-blueGray-300" />
      <div class="flex flex-wrap items-end justify-end">
        <div class="w-full px-4 mx-auto text-right">
          <div class="text-sm text-blueGray-500 font-semibold py-1">
            Copyright © {{ date }} by
            <a
              href="https://www.ibtidah.com"
              class="text-blueGray-500 hover:text-blueGray-800"
            >
              Ibtidah Solutions.
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import AlQadirLogo from "@/assets/img/AlQadir.png";
import NaseehaLogo from "@/assets/img/naseehalogo.png";
export default {
  data() {
    return {
      AlQadirLogo,
      NaseehaLogo,
      date: new Date().getFullYear(),
    };
  },
};
</script>
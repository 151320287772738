<template>
  <div>
    <Navbar />
    <main class="schedule-page">
      <section class="relative block h-500-px">
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1542414110-ae27fdb87ee1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div
          class="
            top-auto
            bottom-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            h-70-px
          "
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>
      <section class="relative py-16 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
              -mt-64
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-start">
                <div class="w-full lg:w-12/12 px-4 lg:order-1">
                  <!-- The Stats -->
                  <div class="flex justify-start py-4 lg:pt-4 pt-8">
                    <div class="mr-4 p-3 text-center">
                      <span
                        class="
                          text-xl
                          font-bold
                          block
                          uppercase
                          tracking-wide
                          text-blueGray-600
                        "
                      >
                        18
                      </span>
                      <span class="text-sm text-blueGray-400">Talks</span>
                    </div>
                    <div class="mr-4 p-3 text-center">
                      <span
                        class="
                          text-xl
                          font-bold
                          block
                          uppercase
                          tracking-wide
                          text-blueGray-600
                        "
                      >
                        2
                      </span>
                      <span class="text-sm text-blueGray-400">Days</span>
                    </div>
                    <div class="lg:mr-4 p-3 text-center">
                      <span
                        class="
                          text-xl
                          font-bold
                          block
                          uppercase
                          tracking-wide
                          text-blueGray-600
                        "
                      >
                        15
                      </span>
                      <span class="text-sm text-blueGray-400">Speakers</span>
                    </div>
                  </div>
                  <!-- The Schedules -->
                  <div class="container py-4 lg:pt-4 pt-8">
                    <div class="flex justify-center">
                      <button
                        class="
                          self-center
                          bg-transparent
                          border border-solid border-lightBlue-500
                          hover:bg-lightBlue-200 hover:text-white
                          font-bold
                          uppercase
                          text-xl
                          px-4
                          py-2
                          rounded
                          outline-none
                          focus:outline-none
                          mr-1
                          mb-1
                          ease-linear
                          transition-all
                          duration-150
                        "
                        v-bind:class="{
                          'text-lightBlue-800 bg-white': openTab !== 0,
                          'text-lightBlue-800 bg-lightBlue-500': openTab === 0,
                        }"
                        @click="onDay1"
                        type="button"
                      >
                        Day 1 (20 Dec 21)
                      </button>
                      <button
                        class="
                          self-center
                          bg-transparent
                          border border-solid border-lightBlue-500
                          hover:bg-lightBlue-200 hover:text-white
                          font-bold
                          uppercase
                          text-xl
                          px-4
                          py-2
                          rounded
                          outline-none
                          focus:outline-none
                          mr-1
                          mb-1
                          ease-linear
                          transition-all
                          duration-150
                        "
                        v-bind:class="{
                          'text-lightBlue-800 bg-white': openTab !== 1,
                          'text-lightBlue-800 bg-lightBlue-500': openTab === 1,
                        }"
                        @click="onDay2"
                        type="button"
                      >
                        Day 2 (21 Dec 21)
                      </button>
                    </div>
                    <div
                      id="Sessions Day 1"
                      class="border-t border-lightBlue-500 mt-2"
                      v-bind:class="{
                        hidden: openTab !== 0,
                        block: openTab === 0,
                      }"
                    >
                      <!-- Session 1 -->
                      <div class="mt-2">
                        <div class="mt-2">
                          <p
                            class="
                              text-blueGray-800
                              bg-lightBlue-500
                              text-xl text-center
                              rounded
                            "
                          >
                            Session 1 (8:30 to 13:00) — Introduction &
                            Orientation
                          </p>
                          <div class="container px-0 md:px-6">
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                8:00 to 9:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Artificial Intelligence: Introduction, Brief
                                  History, Benefits and Ethical Dilemmas
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Junaid Qadir'"
                                    v-bind:link="'/bio/junaidqadir'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                9:00 to 10:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Ethics of Technology: An Islamic Approach
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Amana Raquib'"
                                    v-bind:link="'/bio/amana'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                10:00 to 10:40
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Benefits and Harms of Technology, Maslahah
                                  and Mafsadah
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Mufti Muhammad Akhlaq'"
                                    v-bind:link="'/bio/muftiakhlaq'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                10:40 to 11:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold">Coffee Break </a>
                                <div></div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                11:00 to 11:40
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Risks of AI: Surveillance Capitalism, and
                                  Imperialism
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Shahzeb Khan'"
                                    v-bind:link="'/bio/shahzebkhan'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                11:40 to 12:20
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >The History of Political Economy of
                                  Artificial Intelligence: Reflections for the
                                  Islamization Project
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Omar Javaid'"
                                    v-bind:link="'/bio/omarjavaid'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                12:20 to 13:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Ethics and Norms in digitally emerged public
                                  sphere: The challenge of applicatory ethics
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Abdul Wahab Suri'"
                                    v-bind:link="'/bio/wahabsuri'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                13:00 to 14:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold text-center"
                                  >Break for Prayer
                                </a>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Session 2 -->
                      <div class="mt-2">
                        <div class="mt-2">
                          <p
                            class="
                              text-blueGray-800
                              bg-lightBlue-500
                              text-xl text-center
                              rounded
                            "
                          >
                            Session 2 (14:00 to 18:30) — Islamic Methodology for
                            AI Ethics
                          </p>
                          <div class="container px-0 md:px-6">
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                14:00 to 15:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Relevance of Shah Waliullah’s Philosophy for
                                  Developing an Islamic Approach to AI Ethics
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Mufti Abdul Khaliq Azad'"
                                    v-bind:link="'/bio/khaliqazad'"
                                  ></SpeakerLink>
                                  Translated by:
                                  <SpeakerLink
                                    v-bind:name="'Dr. Shahzeb Khan'"
                                    v-bind:link="'/bio/shahzebkhan'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                15:00 to 16:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >What Islamic AI Ethics Can Learn from Islamic
                                  Bioethics Research: Lessons and Pitfalls
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Aasim Padela'"
                                    v-bind:link="'/bio/aasimpadela'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                16:00 to 16:15
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold">Prayer Break </a>
                                <div></div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                16:15 to 17:15
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Developing an Islamic Methodology for Islamic
                                  Scholarship in the Era of AI</a
                                >
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Jasser Auda'"
                                    v-bind:link="'/bio/jaserauda'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                17:15 to 17:30
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold">Prayer Break </a>
                                <div></div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                17:30 to 18:30
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold">Panel Session </a>
                                <div>
                                  What ontological questions and ethical
                                  dilemmas unite the Bioethics and AI discourse?
                                  Can the takeaways of Islamic Bioethics inform
                                  the Islamic ethical discourse on AI?
                                  Developing Comprehensive Islamic Framework for
                                  Ensuring Human-Beneficial AI <br />— Need for
                                  a Systemic Approach to Law, Public Policy,
                                  Design <br />— Devising Regulations, Public
                                  Policy, Law While Keeping in View the Islamic
                                  Perspective <br />— How to Design AI While
                                  Keeping in View the Islamic Perspective
                                  <br />
                                  <a class="font-bold">Moderator:</a>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Amana Raquib '"
                                    v-bind:link="'/bio/amana'"
                                  ></SpeakerLink>
                                </div>
                                <div>
                                  <a class="font-bold">Panelists:</a>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Aasim Padela'"
                                    v-bind:link="'/bio/aasimpadela'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Mufti Abdul Khaliq Azad'"
                                    v-bind:link="'/bio/khaliqazad'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Jasser Auda'"
                                    v-bind:link="'/bio/jaserauda'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Amir Hussain'"
                                    v-bind:link="'/bio/amirhussain'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Imran Hussain'"
                                    v-bind:link="'/bio/imranhussain'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Athar Mansoor'"
                                    v-bind:link="'/bio/atharmansoor'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Abdul Wahab Suri'"
                                    v-bind:link="'/bio/wahabsuri'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="Sessions Day 1"
                      class="border-t border-lightBlue-500 mt-2"
                      v-bind:class="{
                        hidden: openTab !== 1,
                        block: openTab === 1,
                      }"
                    >
                      <!-- Session 1 -->
                      <div class="mt-2">
                        <div class="mt-2">
                          <p
                            class="
                              text-blueGray-800
                              bg-lightBlue-500
                              text-xl text-center
                              rounded
                            "
                          >
                            Session 3 (8:30 to 13:00) — Viewing AI Through the
                            Lens of Maslaha and Maqasid
                          </p>
                          <div class="container px-0 md:px-6">
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                8:00 - 9:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Maqasid Based Ethics for Artificial
                                  Intelligence
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Ildus Rafikov'"
                                    v-bind:link="'/bio/ildus'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>

                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                9:00 to 10:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Developing Human-Beneficial AI Using Guidance
                                  from Islamic Maqasid
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Amana Raquib'"
                                    v-bind:link="'/bio/amana'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                10:00 to 11:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold">Talk 4</a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Aasim Padela'"
                                    v-bind:link="'/bio/aasimpadela'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>

                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                11:00 to 12:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Islamic Ethics and Artificial Intelligence:
                                  Between Smart City and Virtuous City or Utopia
                                  (Al-madina Al-fadila)
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Mohamed Ghaly'"
                                    v-bind:link="'/bio/ghaly'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>

                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                12:00 to 13:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold">Panel Session </a>
                                <div>
                                  Developing An Authentic Maqasidic Islamic
                                  Framework for Ensuring Human-Beneficial AI
                                  <br />
                                  <a class="font-bold">Moderator:</a>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Amana Raquib '"
                                    v-bind:link="'/bio/amana'"
                                  ></SpeakerLink>
                                </div>
                                <div>
                                  <a class="font-bold">Panelists:</a>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Ildus Rafikov'"
                                    v-bind:link="'/bio/ildus'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Omar Javaid'"
                                    v-bind:link="'/bio/omarjavaid'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Mohamed Ghaly'"
                                    v-bind:link="'/bio/ghaly'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Amir Hussain'"
                                    v-bind:link="'/bio/amirhussain'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Imran Hussain'"
                                    v-bind:link="'/bio/imranhussain'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>

                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                13:00 to 14:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Break for Prayer and Lunch
                                </a>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Session 2 -->
                      <div class="mt-2">
                        <div class="mt-2">
                          <p
                            class="
                              text-blueGray-800
                              bg-lightBlue-500
                              text-xl text-center
                              rounded
                            "
                          >
                            Session 4 (14:00 to 17:15) — Forging a Strategy that
                            Ensurers a Human-Beneficial Future for AI Through
                            Islamic Ethics
                          </p>
                          <div class="container px-0 md:px-6">
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                14:00 to 14:40
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Islamic AI Ethics and the Required Synergy
                                  between Technical and Non-Technical Fields
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Junaid Qadir'"
                                    v-bind:link="'/bio/junaidqadir'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                14:40 to 15:20
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Educating for Character and Virtue in the Era
                                  of AI
                                </a>
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Zeeshan Ahmed'"
                                    v-bind:link="'/bio/zeeshanahmed'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                15:20 to 16:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Muslim Education Online in the Context of
                                  Misinformation, Disinformation, and Moral
                                  Outrage</a
                                >
                                <div>
                                  <SpeakerLink
                                    v-bind:name="'Shaykh Hamza Karamali'"
                                    v-bind:link="'/bio/hamzakaramali'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                16:00 to 16:15
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold">Prayer Break</a>
                                <div></div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                16:15 to 17:00
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold">Panel Session</a>
                                <div>
                                  How to Educate AI Engineers/ AI Designers/ AI
                                  Users? How to Advance the Scholarship in
                                  Islamic AI Ethics and Build Necessary
                                  Collaboration and Outlets?
                                  <br />
                                  <a class="font-bold">Moderator:</a>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Junaid Qadir'"
                                    v-bind:link="'/bio/junaidqadir'"
                                  ></SpeakerLink>
                                </div>
                                <div>
                                  <a class="font-bold">Panelists:</a>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Aasim Padela'"
                                    v-bind:link="'/bio/aasimpadela'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Jasser Auda'"
                                    v-bind:link="'/bio/jaserauda'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Ildus Rafikov'"
                                    v-bind:link="'/bio/ildus'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Amana Raquib'"
                                    v-bind:link="'/bio/amana'"
                                  ></SpeakerLink
                                  ><SpeakerLink
                                    v-bind:name="' Dr. Abdul Wahab Suri'"
                                    v-bind:link="'/bio/wahabsuri'"
                                  ></SpeakerLink
                                  ><SpeakerLink
                                    v-bind:name="'Dr. Muhamed Ghaly'"
                                    v-bind:link="'/bio/ghaly'"
                                  ></SpeakerLink>
                                  <SpeakerLink
                                    v-bind:name="'Dr. Zeeshan Ahmed'"
                                    v-bind:link="'/bio/zeeshanahmed'"
                                  ></SpeakerLink>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Talk"
                              class="flex flex-row mt-2 border-b pb-2"
                            >
                              <div
                                class="mr-2 text-lightBlue-400 self-center"
                                style="width: 10%"
                              >
                                17:00 to 17:15
                              </div>
                              <div
                                class="
                                  w-2
                                  border-r border-2 border-blueGray-800
                                  mr-2
                                "
                              ></div>
                              <div class="w-full flex-1">
                                <a class="font-bold"
                                  >Dua and Concluding Remarks</a
                                >

                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import SpeakerLink from "@/components/SpeakerLink.vue";

import team2 from "@/assets/img/team-2-800x800.jpg";

export default {
  data() {
    return {
      team2,
      openTab: 0,
    };
  },
  components: {
    Navbar,
    FooterComponent,
    SpeakerLink,
  },
  methods: {
    onDay1() {
      this.openTab = 0;
    },
    onDay2() {
      this.openTab = 1;
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Professor Jasser Auda is a scholar of Islam. His latest
                      contribution is a New Maqasid Methodology that aims to
                      bring about a restructuring of Islamic scholarship around
                      a complex network of the higher objectives/Maqasid of the
                      Quran and Prophetic traditions. He is the President of
                      Maqasid Institute Global, a think tank registered and
                      building research and educational projects in a number of
                      countries. He is Al-Shatibi Chair for Maqasid Studies at
                      the International Peace University in South Africa, a
                      Founding and Board Member of the International Union for
                      Muslim Scholars, an Executive Member of the Fiqh Council
                      of North America, a Member of the European Council for
                      Fatwa and Research, and the Chairman of the Canadian Fiqh
                      Council. He has a Ph.D. in the philosophy of Islamic law
                      from the University of Wales, UK, and a Ph.D. in systems
                      analysis from the University of Waterloo, Canada. Early in
                      his life, he memorized the Quran and undertook traditional
                      studies at the Study Circles of Al-Azhar Mosque in Cairo,
                      Egypt. He worked previously as a professor at the
                      universities of Waterloo, Ryerson and Carleton in Canada,
                      Alexandria University in Egypt, Faculty of Islamic
                      Studies, Qatar, American University of Sharjah, UAE, and
                      University of Brunei Darussalam, Brunei. Professor Auda
                      lectured on Islam and its law in dozens of countries, and
                      wrote 25 books in Arabic and English, some of which were
                      translated to 25 languages.
                    </p>
                    <p
                      class="mb-4 text-lg leading-relaxed text-blueGray-700"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/jasserAuda.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Dr. Jasser Auda",
      location: "Canada",
      affiliation: "President, Maqasid Institute",
    };
  },
  components: {},
};
</script>
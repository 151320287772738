<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      style="width: 200px; height: 200px"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Professor of Computer Engineering at the Department of
                      Computer Science and Engineering at Qatar University,
                      Doha, Qatar.
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      He is the director of the IHSAN Lab and also serves as the
                      Chairperson of the Electrical Engineering Department.
                      Before joining ITU in December 2015, he was associated
                      with the School of Electrical Engineering and Computer
                      Science (SEECS) of the National University of Sciences and
                      Technology (NUST) for more than 7 years. He completed his
                      Ph.D. from the University of New South Wales (UNSW),
                      Australia, in 2008, and Bachelors in Electrical
                      Engineering from the University of Engineering and
                      Technology (UET), Lahore, Pakistan. He has published more
                      than 100 peer-reviewed articles at various high-quality
                      research venues including more than 50 impact-factor
                      journal publications at top international research
                      journals including IEEE Communication Magazine, IEEE
                      Journal on Selected Areas in Communication (JSAC), IEEE
                      Communications Surveys and Tutorials (CST), and IEEE
                      Transactions on Mobile Computing (TMC). He was awarded the
                      Higher Education Commission’s (HEC) Best University
                      Teacher Award—the highest national teaching award in
                      Pakistan—for the year 2012-2013. His research interests
                      are in the areas of computer systems and networking;
                      applied artificial intelligence (AI) and machine learning
                      (ML); healthcare informatics; engineering education;
                      ethics for technology; AI ethics; and using ICT for
                      development (ICT4D). In 2020, his project proposal won an
                      award from Facebook Research Ethics in AI Initiative for
                      the Asia Pacific. He is a senior member of IEEE and ACM
                      and has been appointed as an ACM Distinguished Speaker
                      (2020 to 2022).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/junaidQadir.png";

export default {
  data() {
    return {
      team2,
      name: "Dr. Junaid Qadir",
      location: "Qatar",
      affiliation: "Information Technology University and Qatar University",
    };
  },
  components: {},
};
</script>

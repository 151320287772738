<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Dr. Abdul Wahab Suri’s areas of interests are political
                      theory and post-modernity, and he specializes in Liberal
                      Political Theory and its corresponding institutions. He
                      obtained his Master of Arts in Philosophy (1995) and his
                      Ph.D. in Political Philosophy (2004) from the University
                      of Karachi. Dr. Suri’s doctorate dissertation focused on
                      “Philosophical Analysis of Rawls’ Theory of Justice.” He
                      is the Fellow of Kakenhi Project: The Research into
                      Bio-ethics in Asia based on Three Levels Structural
                      Analysis Directed by Professor Takao Takahashi Sansee
                      UNESCO-Kumamoto University 2011-2013. Dr. Suri was the
                      chairman of the Department of Philosophy Oct 2015—Oct
                      2018. Currently He is working as Professor of Philosophy
                      at the Karachi University, and he was In-charge of the
                      Department from 2004 to December 2006. He has also worked
                      as the Director of ORIC of the University of Karachi. He
                      was also the Secretary General of Pakistan Philosophical
                      Congress.
                    </p>
                    <p
                      class="mb-4 text-lg leading-relaxed text-blueGray-700"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/wahabSuri.png";

export default {
  data() {
    return {
      team2,
      name: "Prof. Abdul Wahab Suri",
      location: "Pakistan",
      affiliation: "University of Karachi and PAF-KIET",
    };
  },
  components: {},
};
</script>

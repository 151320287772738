<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Dr. Mohammed Ghaly is Professor of Islam and Biomedical
                      Ethics at the Research Center for Islamic Legislation &
                      Ethics (CILE) at CIS. He has a Bachelor of Arts degree in
                      Islamic Studies from Al-Azhar University (Egypt) and
                      Master of Arts and PhD degrees in the same specialization
                      from Leiden University, the Netherlands. Dr. Ghaly's main
                      specialization is in the intersection of Islamic ethics
                      and biomedical sciences, and he is presently the
                      editor-in-chief of the Journal of Islamic Ethics
                      (published by Brill). Ghaly has lectured on Islamic
                      (bio)ethics at many universities worldwide including
                      Imperial College London, Oxford University, University of
                      Oslo, University of Chicago, and Georgetown University.
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Besides his book, Islam and Disability: Perspectives in
                      Theology and Jurisprudence (Routledge, 2010), and the
                      edited volumes, Islamic Perspectives on the Principles of
                      Biomedical Ethics (Imperial College & World Scientific,
                      2016) and Islamic Ethics and the Genome Question (Brill,
                      2019), Ghaly is the single author of more than 30
                      peer-reviewed publications and serves on the editorial
                      board of a number of academic journals. He is also the
                      Lead Principal Investigator (LPI) and research consultant
                      of a number of funded research projects.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/mohammedGhaly.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Dr. Muhamed Ghaly",
      location: "Qatar",
      affiliation: "CILE, Hamad bin Khalifa University, Qatar",
    };
  },
  components: {},
};
</script>

<template>
  <nav
    class="
      top-0
      fixed
      z-50
      w-full
      flex flex-wrap
      items-center
      justify-between
      px-2
      py-3
      navbar-expand-lg
      bg-white
      shadow
    "
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="
          w-full
          relative
          flex
          justify-between
          lg:w-auto lg:static lg:block lg:justify-start
        "
      >
        <router-link to="/">
          <a
            class="
              text-blueGray-700 text-sm
              font-bold
              leading-relaxed
              inline-block
              mr-4
              py-2
              whitespace-nowrap
              uppercase
            "
            href="#pablo"
          >
            Islamic Ethics and AI
          </a>
        </router-link>
        <button
          class="
            cursor-pointer
            text-xl
            leading-none
            px-3
            py-1
            border border-solid border-transparent
            rounded
            bg-transparent
            block
            lg:hidden
            outline-none
            focus:outline-none
          "
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none mr-auto">
          <li class="flex items-center"></li>
        </ul>
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center">
            <button
              class="
                bg-lightBlue-500
                text-white
                active:bg-lightBlue-600
                text-xs
                font-bold
                uppercase
                px-4
                py-2
                rounded
                shadow
                hover:shadow-lg
                outline-none
                focus:outline-none
                lg:mr-1 lg:mb-0
                ml-3
                mb-3
                ease-linear
                transition-all
                duration-150
              "
              type="button"
              @click="$router.push('/schedule')"
            >
              <i class="fas"></i> Schedule
            </button>
          </li>
          <li class="flex items-center">
            <button
              class="
                text-white
                bg-pink-500
                active:bg-pink-600
                text-xs
                font-bold
                uppercase
                px-4
                py-2
                rounded
                shadow
                hover:shadow-lg
                outline-none
                focus:outline-none
                lg:mr-1 lg:mb-0
                ml-3
                mb-3
                ease-linear
                transition-all
                duration-150
              "
              type="button"
              @click="$router.push('/speakers')"
            >
              <i class="fas"></i> Speakers
            </button>
          </li>
          <li class="flex items-center">
            <button
              class="
                text-white
                bg-blueGray-700
                active:bg-blueGray-600
                text-xs
                font-bold
                uppercase
                px-4
                py-2
                rounded
                shadow
                hover:shadow-lg
                outline-none
                focus:outline-none
                lg:mr-1 lg:mb-0
                ml-3
                mb-3
                ease-linear
                transition-all
                duration-150
              "
              type="button"
              @click="goToRegistration()"
            >
              <i class="fas"></i> Publication
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
// import IndexDropdown from "@/components/Dropdowns/IndexDropdown.vue";

export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
    goToRegistration: function () {
      window.open("https://link.springer.com/article/10.1007/s44163-022-00028-2");
    },
  },
  components: {
    // IndexDropdown,
  },
};
</script>

<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full text-justify lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Dr. Shahzeb Khan has a Ph.D. in English and has worked in
                      the area of institutionalization of literary study which
                      led him to understand the nexus between literature and
                      ideology. His research explains how the discipline was
                      used as a tool to impose cultural imperialism in this
                      region and how the uncritical continuance of this colonial
                      enterprise has paved the way for postcolonial hegemony of
                      thought. His decolonial investigations propelled him into
                      the domain of digital humanities where he is trying to
                      understand the newer and contemporary dimensions of
                      digital hegemonies. Through Indigenzing Knowledge Forum,
                      and Critical Discourse Circle, which he initiated at the
                      University of Punjab, Lahore, Dr. Khan, along with his
                      colleagues, has been trying to evaluate the ubiquity of
                      ideas from the Global North from decolonial and indigenous
                      perspectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/shahzebKhan.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Dr. Shahzeb Khan",
      location: "Pakistan",
      affiliation: "University of Punjab",
    };
  },
  components: {},
};
</script>

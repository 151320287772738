<template>
  <div>
    <index-navbar />
    <section
      class="header relative pt-16 items-center flex h-screen max-h-860-px"
    >
      <div class="container mx-auto items-center sm:mt-4 flex flex-wrap">
        <div class="z-1 w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="pt-32 sm:pt-0">
            <h3 class="font-semibold text-1xl text-blueGray-600">
              20th and 21st, Dec, 2021
            </h3>
            <h3 class="font-semibold text-1xl text-blueGray-600">
              Nishat, Gulberg, Lahore
            </h3>
            <h2 class="font-semibold text-3xl text-blueGray-600">
              International Conference on
            </h2>
            <h1 class="font-semibold text-5xl text-blueGray-700">
              Islamic Ethics and AI
            </h1>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-600">
              Charting a Human Beneficial Future for Artificial Intelligence
              Through Islamic Ethics
            </p>
            <div class="flex flex-wrap mt-12">
              <a
                class="
                  get-started
                  text-white
                  font-bold
                  px-6
                  py-4
                  rounded
                  outline-none
                  focus:outline-none
                  mr-1
                  mb-1
                  bg-lightBlue-500
                  active:bg-lightBlue-600
                  uppercase
                  text-sm
                  shadow
                  hover:shadow-lg
                  ease-linear
                  transition-all
                  duration-150
                "
                href="#videos"
              >
                Watch Conference
              </a>
            </div>
          </div>
        </div>
      </div>

      <img
        style="z-index: -12"
        class="
          absolute
          top-0
          b-auto
          right-0
          pt-16
          sm:w-6/12
          -mt-48
          sm:mt-0
          w-10/12
          max-h-900-px
        "
        :src="patternVue"
        alt="..."
      />
    </section>

    <section class="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
      <div>
        <div
          class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto">
          <div class="flex flex-wrap items-center">
            <div
              class="
                w-11/12
                md:w-6/12
                lg:w-4/12
                px-4
                md:px-4
                mr-auto
                ml-auto
                -mt-32
              "
            >
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  bg-white
                  w-full
                  mb-6
                  shadow-lg
                  rounded-lg
                  bg-lightBlue-600
                "
              >
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1577109688746-ff8681fb7b4c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Hosted at the cultural hub of Pakistan.
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    Joining scholars from around the world to talk about a
                    culturaly inspired AI Ethics framework for the muslim world.
                    What can be better than Lahore - the cultural hub of the
                    second largest Muslim Country.
                  </p>
                </blockquote>
              </div>
            </div>

            <div class="w-full md:w-6/12 px-2">
              <div class="flex flex-wrap">
                <div
                  class="
                    relative
                    flex flex-col
                    min-w-0
                    w-full
                    mb-6
                    mt-48
                    md:mt-0
                    sm:mt-0 sm:pt-0
                  "
                >
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1594493470479-45b9922c9ab8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1036&q=80"
                    class="
                      w-full
                      align-middle
                      rounded
                      absolute
                      shadow-lg
                      max-w-100-px
                      left-145-px
                      -top-29-px
                      z-3
                    "
                  />
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1567862605632-98ec20c667f7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=946&q=80"
                    class="
                      w-full
                      align-middle
                      rounded-lg
                      absolute
                      shadow-lg
                      max-w-210-px
                      left-260-px
                      -top-160-px
                      sm:-top-80-px sm:-left-100-px
                    "
                  />
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1629234932140-49db511736c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80"
                    class="
                      w-full
                      align-middle
                      rounded-lg
                      absolute
                      shadow-lg
                      max-w-210-px
                      left-40-px
                      -top-225-px
                      z-2
                    "
                  />
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1593369534749-85bc2f3cf810?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
                    class="
                      w-full
                      align-middle
                      rounded-lg
                      absolute
                      shadow-2xl
                      max-w-180-px
                      -left-50-px
                      top-20-px
                      sm:h-2
                    "
                  />
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1583429090517-d5a4b9b753f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80"
                    class="
                      w-full
                      align-middle
                      rounded
                      absolute
                      shadow-xl
                      max-w-120-px
                      left-195-px
                      top-95-px
                      sm:h-1
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="videos" class="container mx-auto mt-12 sm:mt-5 px-4 pb-32 pt-48">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-8/12 ml-auto px-12 md:px-4">
            <div class="md:pr-12">
              <h3 class="text-3xl font-semibold">Watch the proceedings!</h3>
              <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                The full proceedings can be viewed here or on
                <a
                  target="_blank"
                  href="https://youtu.be/wIPchnaGczk?list=PL4AueLFeEG0DNu6Y3xjDtRivNtWn_pI_9"
                  >YouTube</a
                >.
              </p>
            </div>
          </div>

          <div class="w-full md:w-3/12 mr-auto px-4 pt-24 md:pt-0">
            <iframe
              class="w-full"
              height="600"
              src="https://www.youtube.com/embed/videoseries?list=PL4AueLFeEG0DNu6Y3xjDtRivNtWn_pI_9"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-16 bg-blueGray-200 relative pt-32">
      <div
        class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
        style="transform: translateZ(0)"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-200 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div class="container mx-auto">
        <div
          class="
            flex flex-wrap
            justify-center
            bg-white
            shadow-xl
            rounded-lg
            -mt-64
            py-16
            px-12
            relative
            z-10
          "
        >
          <div class="w-full text-center lg:w-8/12">
            <p class="text-4xl text-center">
              <span role="img" aria-label="love"> 😊 </span>
            </p>
            <h3 class="font-semibold text-3xl">WHEN & WHERE!</h3>
            <p class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
              The conference is being held at
              <a
                class="text-lightBlue-200"
                href="https://nishathotels.com/explore/johar-town/#?gulberg"
                >Nishat Hotel Gulberg Lahore.
              </a>
              <a class="underline">On 20 and 21 December, 2021</a>
            </p>
            <div class="flex wrap">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.6302161054214!2d74.35387851533221!3d31.506846554961854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919044dc078a3f9%3A0xa7e3c9dec7c9b5f!2sThe%20Nishat%20Hotel%20(Gulberg)%20-%20Lahore!5e0!3m2!1sen!2s!4v1638261692086!5m2!1sen!2s"
                width="800vh"
                height="500vh"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div class="text-center mt-16"></div>
          </div>
        </div>
      </div>
    </section>
    <footer-component />
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

// import patternVue from "@/assets/img/pattern_vue.png";
import patternVue from "@/assets/img/ill_header.png";
// import arabicBackground from "@/assets/img/arabic_background.eps";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";

export default {
  data() {
    return {
      patternVue,
      // arabicBackground,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      profile,
      landing,
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
  },
};
</script>

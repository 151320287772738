<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Dr Zeeshan Ahmed is one of the leading management
                      educationists in the country. He has held key positions in
                      the three top business schools in Pakistan, viz. LUMS, IBA
                      and KSBL. He has also served on the board of country’s
                      eminent Islamic Schools. His mission and passion is to
                      change the society by reforming the education system. He
                      has also studied traditional uloom under the tutelage of
                      eminent scholars.
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Currently, Dr. Zeeshan is Dean at Prime Minister Imran
                      Khan’s Al-Qadir University Project Trust. Prior to joining
                      Al-Qadir he served as the Rector and Dean KSBL an emerging
                      business school set up by Engro group. He has greatly
                      contributed to the institution’s academic growth and
                      sustainability. Earlier, he was heading the undergraduate
                      program at LUMS business school. Dr. Zeeshan has over 25
                      years of diverse experience in teaching, training,
                      consulting and research. Initially, he worked as a
                      management consultant with Ferguson Associates, an
                      affiliate firm of PricewaterhouseCoopers. He has conducted
                      multiple trainings and workshops on topics such as Finance
                      for Non-Financial Managers, Corporate Financial
                      Management, Management Development Program, Understanding
                      Corporate Financial Statements, Islamic Finance, and
                      Accounting for Executives.
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      He completed his Ph.D. from Mississippi State University,
                      US in the discipline of Finance. Professionally, he is a
                      CFA and has also passed all CPA examinations. Recently, he
                      has been working on curriculum and pedagogies that help in
                      developing universally desirable character traits that
                      integral to the Seerah of Prophet ﷺ and his companions.
                      Dr. Zeeshan also serves as Member of Sharia Advisory
                      Committee SECP, CPD Committee of Institute of Chartered
                      Accountants Pakistan (ICAP), Shura Jamiatur Rasheed,
                      Management Committee Reflections School, Board of
                      Directors at Hikmah Institute, Academic Council of IBA
                      Centre for Excellence in Islamic Finance (CEIF) and
                      Patron-in-Chief at Naseeha Institute.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/zeeshanAhmed.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Dr. Zeeshan Ahmed",
      location: "Pakistan",
      affiliation: "Al-Qadir College and Naseeha Institute",
    };
  },
  components: {},
};
</script>

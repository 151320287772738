<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Mufti Abdul Khaliq Raipuri is a contemporary authority of
                      Shāh Walīullah’s thought. Mufti Abdul Khaliq is a
                      traditional scholar and disciple of Maulana Saeed Ahmad
                      Raipuri and the spiritual heir of Khanqah Rahimia Raipur,
                      originally established in India in 1882. Mufti Abdul
                      Khaliq works as the chief patron of the Rahimia Institute
                      of Quranic Sciences, which is an Islamic research and
                      educational organization focused on promoting the
                      comprehensive thought of Shah Waliullah to foster
                      collectivism and the wellbeing of all humanity.
                    </p>
                    <p
                      class="mb-4 text-lg leading-relaxed text-blueGray-700"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/abdulKhaliq.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Mufti Abdul Khaliq Azad",
      location: "Pakistan",
      affiliation: "Rahimia Institute of Quranic Sciences",
    };
  },
  components: {},
};
</script>

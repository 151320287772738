<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full text-justify lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Omar Javaid has a PhD in Entrepreneurship from Institute
                      of Business Management, MS in Management Sciences from
                      KIET with Majors in Islamic Economic and Finance, MBA
                      (executive) from IoBM with majors in Marketing and BE in
                      Industrial & Manufacturing Engineering from NED
                      University. Mr. Javaid has a total of 17 years of
                      experience of working in corporate, non-profit and
                      education sector. Mr. Javaid’s core competence is
                      mentoring new startups and ventures; since 2010 he has
                      helped hundreds of students in their entrepreneurial
                      ambitions. He has also been empowering students to setup
                      micro businesses for the unemployed poor, and have
                      successfully initiated around 600 such projects during his
                      academic career up till now. He has also published various
                      research articles and editorials in various high ranking
                      journals, magazines and news papers. He is a reader of
                      Islamic economics & finance, entrepreneurship, social
                      entrepreneurship, political theory and history,
                      anthropology, depth psychology, and philosophy of
                      economics and business management. His academic work can
                      be accessed on:
                      https://www.researchgate.net/profile/Omar-Javaid-3/research
                    </p>
                    <p
                      class="mb-4 text-lg leading-relaxed text-blueGray-700"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/omarJavaid.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Dr. Omar Javaid",
      location: "Pakistan",
      affiliation:
        " Department of Entrepreneurship, Institute of Business Management",
    };
  },
  components: {},
};
</script>

<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <a target="_blank" rel="noopener noreferrer" :href="website">
                    <i class="fas fa-globe mr-2 text-lg text-blueGray-400"></i>
                    {{ website }}
                  </a>
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Hamza Karamali earned his BASc And MASc in Computer
                      Engineering at the University of Toronto, after which he
                      moved abroad to study the Islamic sciences full-time in
                      private one-on-one settings with distinguished traditional
                      scholars in Kuwait, UAE, and Jordan, reading and
                      memorizing traditional works in all of the Islamic
                      sciences, and earning Bachelor’s and Master’s degrees in
                      Islamic Law and Legal Theory from Jamia Nizamiyya in
                      Hyderabad, India.
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      In the summer of 2019, he founded Basira Education to fill
                      a gap in the religious education of Muslims in the modern
                      world — his goal at Basira is to develop a deploy an
                      original seminary-level curriculum that is grounded in the
                      traditional Islamic sciences but fully integrates modern
                      science and culture into an intelligent and God-centered
                      worldview.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/ShaykhHamza.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Shaykh Hamza Karamali",
      location: "USA",
      affiliation: "Basira Education",
      website: "http://www.hamzakaramali.com/",
    };
  },
  components: {},
};
</script>

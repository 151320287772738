<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Dr. Aasim Padela is an internationally-recognized thought
                      and research leader in the fields of Muslim health
                      disparities and Islamic Bioethics. He is Professor of
                      Emergency Medicine, Bioethics and the Medical Humanities
                      at the Medical College of Wisconsin, and Chairperson and
                      Director of a non-profit organization, the Initiative on
                      Islam and Medicine. He has authored over 120 peer-reviewed
                      journal articles and book chapters, 3 books, and serves in
                      an editorial capacity for the Encyclopedia of Islamic
                      Bioethics, American Journal of Bioethics, BMC Medical
                      Ethics, International Journal of Islam, BETIM Journal of
                      Medical Humanities, and TAHFIM Journal of Islam and the
                      Contemporary World
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Dr. Padela holds an MD with Honors in Research from Weill
                      Cornell Medical College, completed residency in Emergency
                      Medicine with Research Distinction at the University of
                      Rochester, and received an MSc in Healthcare Research from
                      the University of Michigan. He also completed a clinical
                      medical ethics fellowship at the MacLean Center for
                      Clinical Medical Ethics at the University of Chicago, and
                      a research fellowship at the University of Michigan. Prior
                      to that, he received a Bachelor of Science with Highest
                      Distinction in Biomedical Engineering, and a Bachelor of
                      Arts degree with Magna Cum Laude in Classical Arabic and
                      Literature from the University of Rochester. His other
                      notable scholarly training includes visiting fellowships
                      at the Oxford Centre for Islamic Studies and the
                      International Institute for Islamic Thought, and research
                      career development as a Robert Wood Johnson Foundation
                      Clinical Scholar and as a John Templeton Foundation
                      Faculty Scholar. He has studied Islamic theology and law
                      in both seminary and academic settings both nationally and
                      abroad.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/aasimPadela.jpg";

export default {
  data() {
    return {
      team2,
      name: "Dr. Aasim Padela",
      location: "United States",
      affiliation: "Medical College of Wisconsin",
    };
  },
  components: {},
};
</script>

<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="-mt-20 mx-auto object-cover rounded-full"
                      width="200"
                      height="200"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Dr. Athar Mansoor is a researcher in innovation and
                      technology policy, green finance, fintech, digital
                      government and Belt and Road Initiative. He uses
                      frameworks such as the Triple Helix, National Innovation
                      System, Regional Innovation System and Sectoral Innovation
                      System for understanding innovation systems of various
                      developing and developed countries of the world. His
                      current research is focused on describing the National
                      Innovation System of Pakistan and policy response needed
                      to set it on track for taking maximum advantage from the
                      Special Economic Zones of the China Pakistan Economic
                      Corridor. He has presented his research at various
                      prestigious conferences across Asia, Australia, Europe and
                      North America. His work has been published in top journals
                      including Structural Change and Economic Dynamics and the
                      British Medical Journal. Dr. Athar is also serving in the
                      public sector of Pakistan at the federal and provincial
                      levels since 1998. He holds PhD in Public Policy from the
                      Hong Kong University of Science and Technology, MS in
                      Strategy and Leadership from the Michigan State
                      University, Master of Public Policy from the University of
                      Sydney, Master in Public Administration from the National
                      University of Singapore, and Master of Business
                      Administration from the Lahore University of Management
                      Sciences. He has also participated in various executive
                      and professional programs at the Harvard Kennedy School,
                      INSEAD Business School and RWTH Aachen University.
                    </p>
                    <p
                      class="mb-4 text-lg leading-relaxed text-blueGray-700"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/atharMansoor.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Dr. Athar Mansoor",
      location: "Pakistan",
      affiliation:
        "The Shahid Javed Burki Institute of Public Policy at NetSol",
    };
  },
  components: {},
};
</script>

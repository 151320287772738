<template>
  <div>
    <Navbar />
    <main class="schedule-page">
      <section class="relative block h-500-px">
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1542414110-ae27fdb87ee1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1674&q=80');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div
          class="
            top-auto
            bottom-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            h-70-px
          "
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>
      <section class="relative py-16 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
              -mt-64
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-start">
                <div class="w-full lg:w-12/12 px-4 lg:order-1">
                  <!-- The Profiles -->

                  <div class="p-8 bg-white dark:bg-gray-800 rounded-lg">
                    <p
                      class="
                        text-center text-3xl
                        font-bold
                        text-gray-800
                        dark:text-white
                      "
                    >
                      Our Speakers
                    </p>
                    <p
                      class="
                        text-center
                        mb-12
                        text-xl
                        font-normal
                        text-gray-500
                        dark:text-gray-300
                      "
                    >
                      Join us to learn and interact with our Internationaly
                      aclaimed list of speakers.
                    </p>
                    <div
                      class="
                        container
                        px-4
                        mx-auto
                        flex flex-wrap
                        items-center
                        justify-center
                        align-center
                      "
                    >
                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/aasimpadela')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/aasimPadela.jpg"
                                class="
                                  mx-auto
                                  object-cover
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                                style="width: 100px; height: 100px"
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Aasim Padela
                            </span>
                            <span class="text-gray-400 text-xs">
                              Islamic Moral Theology<br />
                              & Biomedicine
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/khaliqazad')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/abdulKhaliq.jpeg"
                                class="
                                  mx-auto
                                  object-contain
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Mufti Abdul Khaliq Azad
                            </span>
                            <span class="text-gray-400 text-xs">
                              Islamic Philosophy
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/wahabsuri')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/wahabSuri.png"
                                class="
                                  mx-auto
                                  object-contain
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                                style="width: 100px; height: 100px"
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Prof. Abdul Wahab Suri
                            </span>
                            <span class="text-gray-400 text-xs">
                              Philosophy
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="p-2 m-2" @click="$router.push('/bio/amana')">
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/amanaRaquib.jpeg"
                                class="
                                  mx-auto
                                  object-contain
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Amana Raquib
                            </span>
                            <span class="text-gray-400 text-xs">
                              Ethics of Technology
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/amirhussain')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/amirHussain.jpeg"
                                class="
                                  mx-auto
                                  object-cover
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                                style="width: 100px; height: 100px"
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Prof. Amir Hussain
                            </span>
                            <span class="text-gray-400 text-xs">
                              Ethical AI
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/atharmansoor')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/atharMansoor.jpeg"
                                class="
                                  mx-auto
                                  object-contain
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Athar Mansoor
                            </span>
                            <span class="text-gray-400 text-xs">
                              Public Policy
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="p-2 m-2">
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/hashimKamali.jpg"
                                class="
                                  mx-auto
                                  object-cover
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Hashim Kamali
                            </span>
                            <span class="text-gray-400 text-xs">
                              Jurisprudence
                            </span>
                          </div>
                        </div>
                      </div> -->

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/hamzakaramali')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/ShaykhHamza.jpeg"
                                class="mx-auto object-cover rounded-full"
                                style="width: 100px"
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Shaykh Hamza Karamali
                            </span>
                            <span class="text-gray-400 text-xs">
                              Islamic Education
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="p-2 m-2" @click="$router.push('/bio/ildus')">
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/ildusRafikov.jpeg"
                                class="
                                  mx-auto
                                  object-cover
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Ildus Rafikov
                            </span>
                            <span class="text-gray-400 text-xs">
                              Islamic Ethics
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/imranHussain')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/imranHussain.jpeg"
                                class="mx-auto object-cover rounded-full"
                                style="width: 100px; height: 100px"
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Imran Hussain
                            </span>
                            <span class="text-gray-400 text-xs">
                              UX & UI Expert
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/jaserauda')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/jasserAuda.jpeg"
                                class="
                                  mx-auto
                                  object-scale-down
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Jasser Auda
                            </span>
                            <span class="text-gray-400 text-xs">
                              Islamic Law
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/junaidqadir')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/junaidQadir.png"
                                class="
                                  mx-auto
                                  object-cover
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                                style="width: 100px; height: 100px"
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Prof. Junaid Qadir
                            </span>
                            <span class="text-gray-400 text-xs">
                              AI Ethics
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="p-2 m-2" @click="$router.push('/bio/ghaly')">
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/mohammedGhaly.jpeg"
                                class="
                                  mx-auto
                                  object-contain
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Mohamed Ghaly
                            </span>
                            <span class="text-gray-400 text-xs">
                              Biomedical Ethics
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/muftiakhlaq')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/muftiAkhlaq.jpeg"
                                class="
                                  mx-auto
                                  object-contain
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Mufti Muhammad Akhlaq
                            </span>
                            <span class="text-gray-400 text-xs">
                              Economy Expert
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/omarjavaid')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/omarJavaid.jpeg"
                                class="
                                  mx-auto
                                  object-contain
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Omar Javaid
                            </span>
                            <span class="text-gray-400 text-xs">
                              Islamic Economics
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/shahzebkhan')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/shahzebKhan.jpeg"
                                class="
                                  mx-auto
                                  object-scale-down
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                                style="width: 100px; height: 100px"
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Shahzeb Khan
                            </span>
                            <span class="text-gray-400 text-xs">
                              Literature & Ideology
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="p-2 m-2"
                        @click="$router.push('/bio/zeeshanahmed')"
                      >
                        <div class="flex-col flex justify-center items-center">
                          <div class="flex-shrink-0">
                            <a href="#" class="block relative">
                              <img
                                alt="profil"
                                src="@/assets/img/Speakers/zeeshanAhmed.jpeg"
                                class="
                                  mx-auto
                                  object-contain
                                  rounded-full
                                  max-h-60 max-w-60
                                "
                              />
                            </a>
                          </div>
                          <div class="mt-2 text-center flex flex-col">
                            <span
                              class="
                                text-gray-600
                                dark:text-white
                                text-lg
                                font-medium
                              "
                            >
                              Dr. Zeeshan Ahmed
                            </span>
                            <span class="text-gray-400 text-xs">
                              Islamic Education
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import Navbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

export default {
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>

<style>
</style>
<template>
  <div>
    <main class="container mx-auto px-4 h-full">
      <section class="flex content-center items-center justify-center h-full">
        <div class="">
          <div
            class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
            "
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="
                        mx-auto
                        object-cover
                        rounded-full
                        max-h-60 max-w-60
                        -mt-20
                      "
                      style="width: 200px; height: 200px"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center mt-12">
                <h3
                  class="
                    text-4xl
                    font-semibold
                    leading-normal
                    mb-2
                    text-blueGray-700
                    mb-2
                  "
                >
                  {{ name }}
                </h3>
                <div
                  class="
                    text-sm
                    leading-normal
                    mt-0
                    mb-2
                    text-blueGray-400
                    font-bold
                    uppercase
                  "
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ location }}
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  {{ affiliation }}
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      Amir Hussain received his B.Eng (highest 1st Class Honours
                      with distinction) and Ph.D degrees, from the University of
                      Strathclyde, Glasgow, U.K., in 1992 and 1997,
                      respectively. Following postdoctoral and academic
                      positions at the Universities of West of Scotland (EPSRC
                      postdoctoral fellow: 1996-98), Dundee (Research Lecturer:
                      1998-2000) and Stirling (Lecturer: 2000-4; Senior
                      Lecturer: 2004-8; Reader: 2008-12; Professor: 2012-18)
                      respectively, he joined Edinburgh Napier University (ENU),
                      in Scotland, UK, in 2018 as a Professor in the School of
                      Computing. He is currently institutional Research Theme
                      Lead for AI and Advanced Technologies and founding Head of
                      the Data Science and Cyber Analytics (DSCA) Research Group
                      (managing over 20 academics and research staff). He is
                      also founding Head of the Cognitive Big Data Analytics
                      (CogBiD) Research Lab, and co-Lead of the Centre for
                      Cardio-Vascular Health (with the School of Health and
                      Social Care).
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      He currently holds a number of Visiting Professorships,
                      including at Xi’an Jiaotong-Liverpool University, Shanghai
                      University and Anhui University. He has previously held
                      Visiting Professorships at the Massachusetts Institute of
                      Technology (MIT - Synthetic Intelligence Lab), USA and
                      University of Oxford (Oxford Computational Neuroscience),
                      UK. He is an elected Executive Committee member of the UK
                      Computing Research Committee (UKCRC) - the National Expert
                      Panel of the Institution of Engineering and Technology
                      (IET) and the BCS, The Chartered Institute for IT, for
                      computing research in the UK. He is also elected Chair of
                      the IEEE UK and Ireland Industry Applications Society
                      Chapter.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import team2 from "@/assets/img/Speakers/amirHussain.jpeg";

export default {
  data() {
    return {
      team2,
      name: "Prof. Amir Hussain",
      location: "United Kingdom",
      affiliation: "School of Computing, Edinburgh Napier University",
    };
  },
  components: {},
};
</script>

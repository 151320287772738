<template>
  <a
    class="rounded-full text-lightBlue-600 bg-lightBlue-200 py-1 px-2"
    @click="onCLick"
    href=""
    >{{ name }}</a
  >
</template>

<script>
export default {
  props: {
    name: String,
    link: String,
  },
  methods: {
    onCLick() {
      this.$router.push(this.link);
    },
  },
};
</script>

<style>
</style>